// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/utils/query.ts"
);
import.meta.hot.lastModified = "1726176605643.3848";
}
// REMIX HMR END

import {
  QueryClient,
  type UseMutationResult,
  type QueryKey,
  type UseMutationOptions as RqUseMutationOptions,
  type UseQueryOptions as RqUseQueryOptions,
  useQueryClient,
  useMutation as rqUseMutation,
  type MutationKey,
  useQuery as rqUseQuery,
  useSuspenseQuery as useRqSuspenseQuery,
  type UseQueryResult as RqUseQueryResult,
  useIsMutating,
  useIsFetching,
  type UseSuspenseQueryOptions as RqUseSuspenseQueryOptions,
  type UseSuspenseQueryResult,
} from '@tanstack/react-query'
import {
  useState,
  useEffect,
  type DependencyList,
  useCallback,
  useMemo,
} from 'react'

import { type PickRequired, type EmptyPOJO, type POJO } from '@/types'

import { useErrorContentToast } from '~/ui/atoms/Toast'

import { type ErrorContent } from '~/api'

import { empties } from '../../lib/constants'

import { type SerializeFrom } from './network'

export const queryClient = new QueryClient()

export type UseQueryResult<
  TData = unknown,
  TError = ErrorContent,
> = RqUseQueryResult<TData, TError> & { isFetchingGlobal: boolean }

export type PrefetchArgs<Args extends POJO = EmptyPOJO> = {
  enabled: boolean
} & Args

export type UseQueryOptions<
  TQueryFnData = unknown,
  TError extends ErrorContent = ErrorContent,
  TData = SerializeFrom<TQueryFnData>,
  TQueryKey extends QueryKey = QueryKey,
> = RqUseQueryOptions<TQueryFnData, TError, TData, TQueryKey>

export type UseSuspenseQueryOptions<
  TQueryFnData = unknown,
  TError extends ErrorContent = ErrorContent,
  TData = SerializeFrom<TQueryFnData>,
  TQueryKey extends QueryKey = QueryKey,
> = RqUseSuspenseQueryOptions<TQueryFnData, TError, TData, TQueryKey>

export type UseMutationOptions<
  TData = unknown,
  TError extends ErrorContent = ErrorContent,
  // TODO: this should default to void and TData should default to SerializeFrom
  MutateArgs = SerializeFrom<TData>,
  TContext = unknown,
> = RqUseMutationOptions<TData, TError, MutateArgs, TContext>

export type PartialUseQueryOptions<
  TQueryFnData = unknown,
  TError extends ErrorContent = ErrorContent,
  TData = SerializeFrom<TQueryFnData>,
  TQueryKey extends QueryKey = QueryKey,
> = Omit<
  Partial<RqUseQueryOptions<TQueryFnData, TError, TData, TQueryKey>>,
  'queryKey' | 'queryFn'
>

export type PartialUseMutationOptions<
  TData = unknown,
  TError extends ErrorContent = ErrorContent,
  // TODO: this should default to void and TData should default to SerializeFrom
  MutateArgs = SerializeFrom<TData>,
  TContext = unknown,
> = Omit<
  Partial<RqUseMutationOptions<TData, TError, MutateArgs, TContext>>,
  'mutationKey' | 'mutationFn'
>

/**
 * This hook wrapper around the default react-query `useQuery`
 * which uses ErrorContent as the error type. Use this with httpWithThrow
 */
export function useQuery<
  TQueryFnData = unknown,
  TError extends ErrorContent = ErrorContent,
  TData = TQueryFnData,
  TQueryKey extends QueryKey = QueryKey,
>(
  opts: RqUseQueryOptions<TQueryFnData, TError, TData, TQueryKey>,
  client?: QueryClient,
): UseQueryResult<TData, TError> {
  const isFetchingGlobal = useIsFetching({ queryKey: opts.queryKey }) > 0
  const queryResult = rqUseQuery<TQueryFnData, TError, TData, TQueryKey>(
    opts,
    client,
  )
  return Object.assign(queryResult, { isFetchingGlobal })
}

/**
 * This hook wrapper around the default react-query `useQuery`
 * which uses ErrorContent as the error type. Use this with httpWithThrow
 */
export function useSuspenseQuery<
  TQueryFnData = unknown,
  TError extends ErrorContent = ErrorContent,
  TData = TQueryFnData,
  TQueryKey extends QueryKey = QueryKey,
>(
  opts: RqUseSuspenseQueryOptions<TQueryFnData, TError, TData, TQueryKey>,
  client?: QueryClient,
): UseSuspenseQueryResult<TData, TError> & { isFetchingGlobal: boolean } {
  const isFetchingGlobal = useIsFetching({ queryKey: opts.queryKey }) > 0
  const queryResult = useRqSuspenseQuery<
    TQueryFnData,
    TError,
    TData,
    TQueryKey
  >(opts, client)
  return Object.assign(queryResult, { isFetchingGlobal })
}

/**
 * This hook is just a wrapper around the default react-query `useMutation`
 * with the error type set to ErrorContent, and isPendingGlobal added in.
 * Use this with httpWithThrow
 */
export function useMutation<
  TData = unknown,
  TError extends ErrorContent = ErrorContent,
  MutateArgs = void,
  TContext = unknown,
>(
  opts: Omit<
    RqUseMutationOptions<TData, TError, MutateArgs, TContext>,
    'mutationKey'
  > & {
    mutationKey: MutationKey
  },
  client?: QueryClient,
): UseMutationResult<TData, TError, MutateArgs, TContext> & {
  isPendingGlobal: boolean
} {
  const isPendingGlobal = useIsMutating({ mutationKey: opts.mutationKey }) > 0
  const mutationResult = rqUseMutation(opts, client)
  return Object.assign(mutationResult, { isPendingGlobal })
}

export function useOptimisticMutation<
  /** Returned Data Type -- const { data } = useMutation() */
  TData = unknown,
  /** Returned Error Type -- const { error } = useMutation() */
  TError extends ErrorContent = ErrorContent,
  /**
   * Arguments sent to mutateFn -- const { mutate } = useMutation()
   * mutate(mutateArgs)
   **/
  MutateArgs extends {
    args: unknown
    // TODO: update optimisticValue to an array so we can optimistically update multiple queries
    optimisticData: { key: QueryKey; value: unknown }
  } = {
    args: TData
    optimisticData: { key: QueryKey; value: TData }
  },
  /** The last arg passed to onSuccess, onError, and onSettled. This is determined by the returned value of onMutate. */
  TContext extends { previousValue: unknown } = { previousValue: TData },
>(
  options: Parameters<
    typeof useMutation<TData, TError, MutateArgs, TContext>
  >[0],
  client?: QueryClient,
): UseMutationResult<TData, TError, MutateArgs, TContext> {
  const { onMutate, onError, onSuccess, ...opts } = options
  const errorToast = useErrorContentToast()
  const qc = useQueryClient()

  return useMutation(
    {
      onMutate: async (mutateArgs: MutateArgs): Promise<TContext> => {
        const { optimisticData } = mutateArgs
        // Cancel any outgoing refetches
        // (so they don't overwrite our optimistic update)
        await qc.cancelQueries({ queryKey: optimisticData.key })

        // Snapshot the previous value in case of failure
        const previousValue = qc.getQueryData(optimisticData.key)

        // Optimistically update to the new value
        qc.setQueryData(optimisticData.key, optimisticData.value)

        // Return a context object with the snapshotted value
        const userMutateFnResult = onMutate?.(mutateArgs) ?? undefined
        return Object.assign(userMutateFnResult ?? {}, {
          previousValue,
        }) as TContext
      },
      // If the mutation fails,
      // use the context returned from onMutate to roll back
      onError: (err, mutateArgs, context) => {
        qc.setQueryData(mutateArgs.optimisticData.key, context?.previousValue)
        errorToast(err, 'Update failed')
        onError?.(err, mutateArgs, context)
      },
      ...opts,
    },
    client,
  )
}

export function usePrefetchQuery<
  TQueryFnData = unknown,
  TError extends ErrorContent = ErrorContent,
  TData = TQueryFnData,
  TQueryKey extends QueryKey = QueryKey,
>(
  options: PickRequired<
    UseQueryOptions<TQueryFnData, TError, TData, TQueryKey>,
    'enabled'
  >,
  qClient: QueryClient = queryClient,
  deps: DependencyList = empties.array,
) {
  useEffect(() => {
    if (options.enabled) {
      qClient.ensureQueryData(options)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [qClient, options, ...deps])
}

export function useDefaultPrefetchTriggers() {
  const [enabled, setEnabled] = useState(false)
  const onMouseEnter = useCallback(() => {
    setEnabled(true)
  }, [])
  const onMouseLeave = useCallback(() => {
    setEnabled(false)
  }, [])
  const onFocus = useCallback(() => {
    setEnabled(true)
  }, [])
  const onBlur = useCallback(() => {
    setEnabled(false)
  }, [])
  return useMemo(
    () => ({
      enabled,
      onMouseEnter,
      onMouseLeave,
      onFocus,
      onBlur,
    }),
    [enabled, onBlur, onFocus, onMouseEnter, onMouseLeave],
  )
}
